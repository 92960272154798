import React from 'react';
import './style.scss';

const index = () => {
  return (
    <div className='Loader'>
      <h1 className='TItle'>
        Diego Díaz - Frontend developer
      </h1>
      Cargando...
    </div>
  );
};

export default index;