import * as THREE from 'three'
import {  useEffect, useRef, useState } from 'react'
import {  useLoader, useThree } from '@react-three/fiber'
import {  ScrollControls,useHelper, Scroll, MeshReflectorMaterial, OrbitControls, Text } from '@react-three/drei'
import { TextureLoader } from'three/src/loaders/TextureLoader.js';
import { DirectionalLightHelper } from 'three'
import { useSpring, animated } from '@react-spring/three'
import gsap from 'gsap';
import './Style.scss'

import Info from './../Info';
const Gallery = ({
  //Info props
  NameOfProject,
  DescriptionOfProject,
  TechnologiesOfProject,
  TeamOfProject,
  LinkProject,
  Image_1,
  Image_2,
  Image_3,
  colorScene
}) => {


  //Posicion 1
  const { position } = useSpring({
    to: {
      position: [1.1, 1.2, -2]
    },
    from: [1.1, 3 ,-2],
    config: { mass: 50, tension: 5000, friction: 350 }
  })
  //Lateral 1
  const { position4 }  = useSpring({
    to: {
      position4: [1.1,1.2,-7]
    },
    from: [1.1,3,-7],
    config: { mass: 150, tension: 5000, friction: 1000 }
  })

  //Posicion 2
  const { position2 } = useSpring({
    to: {
      position2: [12,1.2,-2]
    },
    from: [12,5,-2],
    config: { mass: 150, tension: 5000, friction: 1000 }
  })
  //Lateral 2
  const { lateral2 }  = useSpring({
    to: {
      lateral2: [12,1.2,-7]
    },
    from: [12,3,-7],
    config: { mass: 150, tension: 5000, friction: 1000 }
  })

  //Posicion 3
  const { position3 }  = useSpring({
    to: {
      position3: [24,1.2,-2]
    },
    from: [24,5,-2],
    config: { mass: 150, tension: 5000, friction: 1000 }
  })

  

  

  //Textures
  const texture_1 = useLoader(TextureLoader, Image_1);
  texture_1.mapping = THREE.EquirectangularReflectionMapping;
  texture_1.magFilter = THREE.NearestFilter;
  texture_1.minFilter = THREE.LinearMipMapLinearFilter;

  const texture_2 = useLoader(TextureLoader, Image_2);
  texture_1.mapping = THREE.EquirectangularReflectionMapping;
  texture_1.magFilter = THREE.NearestFilter;
  texture_1.minFilter = THREE.LinearMipMapLinearFilter;

  const texture_3 = useLoader(TextureLoader, Image_3);
  texture_1.mapping = THREE.EquirectangularReflectionMapping;
  texture_1.magFilter = THREE.NearestFilter;
  texture_1.minFilter = THREE.LinearMipMapLinearFilter;

  
  const Light = useRef()
  useHelper(Light, DirectionalLightHelper, 0.5, "hotpink")
  

  //Gsap Animation
  useEffect(()=>{
    gsap.to('#TitleProjectBlock',{
      duration:1,
      translateX:'105%'
    })
    gsap.to('#Title__Description',{
      duration:2,
      opacity:1
    })
  })
  return (
    <>


      <color attach="background" args={[colorScene]} />
      
      <ambientLight intensity={1} />


      <ScrollControls
        pages={3} // Each page takes 100% of the height of the canvas
        distance={1} // A factor that increases scroll bar travel (default: 1)
        damping={6} // Friction, higher is faster (default: 4)
        horizontal={true} // Can also scroll horizontally (default: false)
        infinite={false} // Can also scroll infinitely (default: false)
      > 

        <Scroll>

            <animated.mesh position={position} rotation={[0,-.5,0]} >
              <boxGeometry attach="geometry" args={[8, 5,0]}  />
              <meshStandardMaterial 
                attach="material"
                transparent
                map={ texture_1  }  
                
              />
            </animated.mesh>

            <animated.mesh position={position2} rotation={[0,-.5,0]} >
              <boxGeometry attach="geometry" args={[8, 5,0]}  />
              <meshStandardMaterial 
                attach="material"
                transparent
                map={ texture_2  }  
                
              />
            </animated.mesh>


            <animated.mesh position={position3} rotation={[0,-.5,0]} >
              <boxGeometry attach="geometry" args={[8, 5,0]}  />
              <meshStandardMaterial 
                attach="material"
                transparent
                map={ texture_3  }  
              />
            </animated.mesh>


            {/**Laterales */}
            <animated.mesh position={position4} rotation={[0,.5,0]} >
              <boxGeometry attach="geometry" args={[8, 5,0]}  />
              <meshStandardMaterial 
                attach="material"
                transparent
                map={ texture_1  }  
              />
            </animated.mesh>

            <animated.mesh position={lateral2} rotation={[0,.7,0]} >
              <boxGeometry attach="geometry" args={[8, 5,0]}  />
              <meshStandardMaterial 
                attach="material"
                transparent
                map={ texture_1  }  
              />
            </animated.mesh>
            

            <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, -1.5, 0]}>
                <planeGeometry args={[150, 150]} />
                <MeshReflectorMaterial
                  blur={[300, 100]}
                  resolution={2048}
                  mixBlur={1}
                  mixStrength={10}
                  roughness={1}
                  depthScale={25}
                  minDepthThreshold={0.4}
                  maxDepthThreshold={1.4}
                  color="#101010"
                  metalness={0.5}
                />
            </mesh>
        </Scroll>

        <Scroll  html>
          <Info 
            NameOfProject={NameOfProject}
            DescriptionOfProject={DescriptionOfProject}
            TechnologiesOfProject={TechnologiesOfProject}
            TeamOfProject={TeamOfProject}
            LinkProject={LinkProject}
          />
          
        </Scroll>


      </ScrollControls>

      <OrbitControls enableZoom={false}  />
      
    </>
  );
};

export default Gallery;