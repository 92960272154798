import { HashRouter  as Router, Route, Routes } from 'react-router-dom'

import Project from './../Views/Aconium';
import React from 'react';

const index = () => {
  return (
    <Router  basename={'/'}>
      <Routes>
        
        <Route  exact path="/" element={
          <Project 
            Image_1 = 'https://res.cloudinary.com/desxa2pol/image/upload/v1711558971/aconium-1_shrs81.png'
            Image_2 = 'https://res.cloudinary.com/desxa2pol/image/upload/v1711558960/aconium-4_sk4k2c.png'
            Image_3 = 'https://res.cloudinary.com/desxa2pol/image/upload/v1711558960/aconium-3_dbbct0.png'
            colorScene='#0E3D45'

            NameOfProject = 'Aconium'
            DescriptionOfProject = 'Aconium es un proyecto inmobiliario creado para presentar las propiedades de una manera creativa'
            TechnologiesOfProject='html, css javascript, jquery, linux, php y mysql '
            TeamOfProject={{
              designer:'Ariel García',
              frontend:'Diego Díaz',
              backend:'Diego Díaz'
            }}
            LinkProject='https://diegodiaz.com/aconium'
          />
        }/>

        <Route path="/caledonian" element={
          <Project 
            Image_1 = 'https://res.cloudinary.com/desxa2pol/image/upload/v1711560305/FireShot_Capture_183_-_React_App_-_localhost_h3ezea.png'
            Image_2 = 'https://res.cloudinary.com/desxa2pol/image/upload/v1711560305/FireShot_Capture_184_-_React_App_-_localhost_nkwp9e.png'
            Image_3 = 'https://res.cloudinary.com/desxa2pol/image/upload/v1711560296/FireShot_Capture_185_-_React_App_-_localhost_u2za3o.png'
            colorScene='#C80E22'

            NameOfProject = 'Caledonian'
            DescriptionOfProject = 'Landing page del sector inmobiliario desarrollado con react donde destaca el uso de gsap para crear animaciones basadas en el scroll. (Vista móvil no disponible) '
            TechnologiesOfProject='React, Gsap, Sass, Javascript, Ecmascript'
            TeamOfProject={{
              designer:'Caledonian',
              frontend:'Diego Díaz',
              backend:'No aplica'
            }}
            LinkProject='https://caledonian.diegodiaz.com.mx/'
          />
        }/>

        <Route path="/des" element={
          <Project 
            Image_1 = 'https://res.cloudinary.com/desxa2pol/image/upload/v1711560903/FireShot_Capture_186_-_Desfinanciera_-_des-financiera.diegodiaz.com.mx_jazax3.png'
            Image_2 = 'https://res.cloudinary.com/desxa2pol/image/upload/v1711560881/FireShot_Capture_187_-_Desfinanciera_-_des-financiera.diegodiaz.com.mx_ooabgd.png'
            Image_3 = 'https://res.cloudinary.com/desxa2pol/image/upload/v1711560878/FireShot_Capture_188_-_Desfinanciera_-_des-financiera.diegodiaz.com.mx_eg5krh.png'
            colorScene='#FF002E'

            NameOfProject = 'DES Financiera - Grupo Dalton'
            DescriptionOfProject = 'Landing page creada para promocionar el servicio de financiación de autos de grupo Dalton.'
            TechnologiesOfProject='Html, sass, webpack'
            TeamOfProject={{
              designer:'Grupo Dalton',
              frontend:'Diego Díaz',
              backend:'No aplica'
            }}
            LinkProject='https://des-financiera.diegodiaz.com.mx/'
          />
        }/>

        <Route path="/price-shoes" element={
          <Project 
            Image_1 = 'https://res.cloudinary.com/desxa2pol/image/upload/v1711561210/FireShot_Capture_189_-_Price_Shoes_-_www.priceshoes.com_tbv663.png'
            Image_2 = 'https://res.cloudinary.com/desxa2pol/image/upload/v1711562088/FireShot_Capture_191_-_Price_Shoes_-_www.priceshoes.com_ol43hs.png'
            Image_3 = 'https://res.cloudinary.com/desxa2pol/image/upload/v1711560878/FireShot_Capture_188_-_Desfinanciera_-_des-financiera.diegodiaz.com.mx_eg5krh.png'
            colorScene='#02034B'

            NameOfProject = 'Price Shoes'
            DescriptionOfProject = 'Mi tarea fue convertir a "vista de detalle de producto" en una página  moderna y bella, que sea fácil de usar y al mismo tiempo sea muy elegante con el objetivo que incrementemos las ventas entre las 55 millones de visitas que tiene esta sección de price shoes'
            TechnologiesOfProject='React, Next, Sass'
            TeamOfProject={{
              designer:'PriceShoes',
              frontend:'Diego Díaz',
              backend:'PriceShoes'
            }}
            LinkProject='https://www.priceshoes.com/productos/tenis-casual-breaknet-2-01144331'
          />
        }/>

        {/* <Route  path="/aconium" element={
          <Project 
            Image_1 = 'https://diegodiaz.rocks/assets/aconium_1.png'
            Image_2 = 'https://diegodiaz.rocks/assets/aconium_2.png'
            Image_3 = 'https://diegodiaz.rocks/assets/aconium_3.png'
            colorScene='#0E3D45'

            NameOfProject = 'Aconium'
            DescriptionOfProject = 'Aconium es un proyecto inmobiliario creado para presentar las propiedades de una manera creativa'
            TechnologiesOfProject='html, css javascript, jquery, linux, php y mysql '
            TeamOfProject={{
              designer:'Ariel García',
              frontend:'Diego Díaz',
              backend:'Diego Díaz'
            }}
            LinkProject='https://diegodiaz.rocks/aconium'
          />
        }/> */}

        {/* <Route path="/caledonian" element={
          <Project 
            Image_1 = 'https://diegodiaz.rocks/assets/caledonian_1.png'
            Image_2 = 'https://diegodiaz.rocks/assets/caledonian_2.png'
            Image_3 = 'https://diegodiaz.rocks/assets/caledonian_3.png'
            colorScene='blue'

            NameOfProject = 'Caledonian'
            DescriptionOfProject = 'Landing page del sector inmobiliario desarrollado con react donde destaca el uso de gsap para crear animaciones basadas en el scroll. (Vista móvil no disponible) '
            TechnologiesOfProject='React, Gsap'
            TeamOfProject={{
              designer:'Caledonian',
              frontend:'Diego Díaz',
              backend:'No aplica'
            }}
            LinkProject='https://diegodiaz.rocks/caledonian'
          />
        }/>

        <Route  path="/fx-entrepreneurs" element={
          <Project 
            Image_1 = 'https://diegodiaz.rocks/assets/Fx_1.png'
            Image_2 = 'https://diegodiaz.rocks/assets/Fx_2.png'
            Image_3 = 'https://diegodiaz.rocks/assets/Fx_3.png'
            colorScene='#341223'

            NameOfProject = 'FX Entrepreneurs'
            DescriptionOfProject = 'Este es un proyecto creado para la escuela de trading Fx Academy, conformada por una página web corporativa y una landing page que se utilizó en campañas de google ads.'
            TechnologiesOfProject='html, css javascript, jquery y cakephp'
            TeamOfProject={{
              designer:'Ariel García',
              frontend:'Diego Díaz',
              backend:'Diego Díaz'
            }}
            LinkProject='https://diegodiaz.rocks/fx'
          />
        }/>

        <Route  path="/slu" element={
          <Project 
            Image_1 = 'https://diegodiaz.rocks/assets/slu_1.png'
            Image_2 = 'https://diegodiaz.rocks/assets/slu_2.png'
            Image_3 = 'https://diegodiaz.rocks/assets/slu_3.png'
            colorScene='#003D6D'

            NameOfProject = 'SLU Underwriters'
            DescriptionOfProject = 'CRM creado para la gestión empresarial de la reaseguradora SLU underwriters incluye módulos para visualizar información, redactar email y muchos más componentes creados desde cero'
            TechnologiesOfProject='Vue js'
            TeamOfProject={{
              designer:'The rocket code',
              frontend:'Diego Díaz',
              backend:'The rocket code'
            }}
            LinkProject='https://diegodiaz.rocks/fx'
          />
        }/>

        <Route  path="/ttrae" element={
          <Project 
          Image_1 = 'https://diegodiaz.rocks/assets/ttrae_1.png'
          Image_2 = 'https://diegodiaz.rocks/assets/ttrae_2.png'
          Image_3 = 'https://diegodiaz.rocks/assets/ttrae_3.png'
          colorScene='#341223'

          NameOfProject = 'T-trae comercios'
          DescriptionOfProject = 'Ttrae es un marketplace conformado por 3 aplicaciones, una para comercios, otra para repartidores y otra para la administración general todas desarrolladas y diseñadas por mi tanto el frontend como el backend, además de trabajar en conjunto con más ingenieros para crear las aplicaciones móviles.'
          TechnologiesOfProject='html, css javascript, vuejs, axios, php, mysql, google cloud, chartjs, api mercadopago, api paypal, api google maps '
          TeamOfProject={{
            designer:'Diego Díaz',
            frontend:'Diego Díaz',
            backend:'Diego Díaz'
          }}
          LinkProject='#'
          />
        }/>


        {/**Aún no estan listos */}
        {/* <Route  path="/biorad" element={
          <Project 
            NameOfProject = 'Biorad'
            DescriptionOfProject = 'Aconium'
            TechnologiesOfProject='html, css javascript, jquery, linux, php y mysql '
            TeamOfProject={{
              designer:'Ariel García',
              frontend:'Diego Díaz',
              backend:'Diego Díaz'
            }}
          />
        }/>

        <Route  path="/ttrae-cliente" element={
          <Project 
            NameOfProject = 'Biorad'
            DescriptionOfProject = 'Aconium'
            TechnologiesOfProject='html, css javascript, jquery, linux, php y mysql '
            TeamOfProject={{
              designer:'Ariel García',
              frontend:'Diego Díaz',
              backend:'Diego Díaz'
            }}
          />
        }/>


        <Route  path="/bivrando" element={
          <Project 
            NameOfProject = 'Bivrando'
            DescriptionOfProject = 'Aconium'
            TechnologiesOfProject='html, css javascript, jquery, linux, php y mysql '
            TeamOfProject={{
              designer:'Ariel García',
              frontend:'Diego Díaz',
              backend:'Diego Díaz'
            }}
          /> 
        }/>*/}
      </Routes>
      
    </Router>
  );
};

export default index;