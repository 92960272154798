import { Suspense } from 'react';
import { Canvas } from '@react-three/fiber'
import Gallery from './../../Components/Gallery';
import Loading from './../../Components/Loading';

const index = ({
  NameOfProject,
  DescriptionOfProject,
  TechnologiesOfProject,
  TeamOfProject,
  Image_1,
  Image_2,
  Image_3,
  colorScene,
  LinkProject
}) => {
  return (
    <>
    <Loading />
    <Canvas 
        flat={true}
        linear={true}
    >
      <Suspense fallback={ null } >
        <Gallery 
          //Info Props
          NameOfProject={NameOfProject}
          DescriptionOfProject={DescriptionOfProject}
          TechnologiesOfProject={TechnologiesOfProject}
          TeamOfProject={TeamOfProject}
          LinkProject={LinkProject}
          Image_1={Image_1} 
          Image_2={Image_2}
          Image_3={Image_3}
          colorScene={colorScene}
        />
      </Suspense>
    </Canvas> 
    </>
  );
};

export default index;