import React from 'react';
import './style.scss';
const index = ({
  //InfoProps
  NameOfProject,
  DescriptionOfProject,
  TechnologiesOfProject,
  TeamOfProject,
  LinkProject
}) => {
  return (
    <div className='Info'>
    {/**Top Bar */} 
    <div className='TopBar'>
      <div className='Autor'>
        Diego Díaz <br />
        <span className='color'>Frontend</span> developer <br /> 
        Creative Developer
      </div>
    </div>

    {/**Middle Bar */}
    <div className='MiddleBar'>

      {/**Name of project */}
      <div className='NameProject'>
        <div className='Name'>
          {NameOfProject}
        </div>
      </div>

      {/**About project */}
      <div className='AboutProject'>
        <div className='About'>
          { DescriptionOfProject }

          <a className='Button' href={LinkProject} target='_blank'>
            Visitar proyecto
          </a>
        </div>
      </div>
    </div>
    {/**Bottom Bar */}
      <div className='BottomBar'>
        {/**Info Cards*/}
        <div className='BottomBlock'>
          <div className='BottomInfo'>
            <h2 className='BottomInfo__Title color'>
              Tecnologías usadas 
            </h2>
            <p className='BottomInfo__Text' >
              Este proyecto lo desarrollé con 
                 <b>
                  {TechnologiesOfProject}
                </b>
            </p>
          </div>
        </div>

        <div className='BottomBlock'>
          <div className='BottomInfo'>
            <h2 className='BottomInfo__Title color'>
              Team de desarrollo
            </h2>
            <p className='BottomInfo__Text'>
              Diseño UI / UX - <b>{TeamOfProject.designer}</b><br />
              Desarrollo frontend: <b> {TeamOfProject.frontend}</b> <br />
              Desarrollo backend: <b> {TeamOfProject.backend}</b> <br />
            </p>
          </div>
        </div>


        <div className='BottomBlock'>
          <div className='BottomInfo'>
            <h2 className='BottomInfo__Title color'>
              Contáctame
            </h2>
            <p className='BottomInfo__Text'>
              <b>Whatsapp / Cel</b> +52 55 2420 8401<br/>
              <b>Mail</b> Luisdiazdhz@gmail.com<br />
              {/* <b>YouTube</b> Diego Díaz */}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;